import { Link, navigate } from 'gatsby';
import React from 'react';
import { connect } from 'react-redux';
import EnsoSteps from '../components/enso-steps';
import Layout from '../components/layout';
import * as actions from '../state/actions';
import '../styles/pages/confirmation.css';

// TODO: Conditionally fetch state from backend to verify that the source and
//     target ords are logged in and the migration may be started.
//     Conditionally, to avoid superfluous request if the state has been
//     fetched recently.

const ConfirmationPage = ({ registration, startMigration }) => (
    <Layout requireLogin className="confirmation-page container">
        <div className="stepper">
            <EnsoSteps step={2} />
        </div>
        <div className="btns-primary">
            <h1 className="pb-4">
                Ready to migrate?
            </h1>
            <Link
                className="btn btn-secondary btn-large"
                to="/connections/"
            >
                Back
            </Link>
            <button
                className="btn btn-primary btn-large float-right"
                onClick={() =>
                    startMigration(registration)
                        .then(() => navigate('/summary/'))
                        // TODO: There has got to be a better way to manage
                        //     API requests in Redux without having to
                        //     swallow exceptions or lose the ability to
                        //     distinguish between successes and failures.
                        .catch(() => {})
                }
            >
                Migrate
            </button>
        </div>
    </Layout>
);

export default connect(
    ({ enso: { registration } }) => ({ registration }),
    { startMigration: actions.startMigration },
)(ConfirmationPage);